<script>
    import WhatWeDoCard from "./WhatWeDoCard.svelte";
</script>

<section class="relative">
    <div
            class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
            style="transform: translateZ(0);"
    >
        <svg
                class="absolute bottom-0 overflow-hidden"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                xmlns="http://www.w3.org/2000/svg"
                y="0"
        >
            <polygon
                    class="text-blueGray-100 fill-current"
                    points="2560 0 2560 100 0 100"
            />
        </svg>
    </div>
    <div class="content">
        <div class="what-we-offer-card neu_card">
            <img
                    alt="..."
                    class="w-full align-middle rounded-t-lg"
                    src="/assets/img/busy-project-manager-overwhelmed-by-work.svg"
            />
            <blockquote class="relative">
                <svg
                        class="absolute left-0 w-full block h-95-px -top-94-px"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon class="fill-current" points="-30,95 583,95 583,65"/>
                </svg>
                <h4 class="text-xl font-bold highlight">What we offer?</h4>
                <p class="text-md font-light mt-2">
                    Technical implementation, in our opinion, only accounts for 20% of success.
                    The remaining <span class="highlight">80%</span> is strategy. Therefore, we focus on
                    developing the best
                    <span class="highlight">digital strategy</span>, while being <span
                        class="highlight">realistic</span>
                    and delivering <span class="highlight">faultless execution</span>.
                </p>
            </blockquote>
        </div>
        <WhatWeDoCard>
            <span slot="icon"><i class="fas fa-gopuram"></i></span>
            <span slot="title">Our Products</span>
            <div slot="content">
                <ul style="list-style-type: disclosure-closed; list-style-position: inside"
                    class="mb-4 text-blueGray-500">
                    <li>Can <span class="tooltip">
                                lego-coding
                                <span class="tooltiptext">a term we like to call our technique, which is a combination
                                    of a few tools and coding practice that can enhance the process of writing code
                                </span>
                            </span>
                        <span class="highlight">enhance</span> the way we <span
                                class="highlight">develop software</span> today?
                    </li>
                    <li>Can all silo-ed <span class="highlight">analytics</span> be brought <span
                            class="highlight">under one roof</span>?
                    </li>
                    <li>Can you <span class="highlight">run</span> an <span
                            class="highlight">enterprise</span> with just a
                        <span class="highlight">tablet</span>?
                    </li>
                </ul>
                <p class="mb-4 text-blueGray-500">
                    These questions bring us to office on Monday mornings.
                </p>
            </div>
        </WhatWeDoCard>
        <WhatWeDoCard>
                        <span slot="icon">
                            <i class="fas fa-ruler-combined"></i>
                        </span>
            <span slot="title">Product Customisations</span>
            <div slot="content">
                <p class="mb-4 text-blueGray-500">
                    Your use-cases are not fully addressed by our products?
                </p>
                <p class="mb-4 text-blueGray-500">
                    Let us <span class="highlight">tailor</span> our offerings to precisely meet your <span
                        class="highlight">needs</span>.
                </p>
            </div>
        </WhatWeDoCard>
        <WhatWeDoCard>
                        <span slot="icon">
                            <i class="fas fa-tape"></i>
                        </span>
            <span slot="title">Services</span>
            <div slot="content">
                <p class="mb-4 text-blueGray-500">
                    Do you have <span class="highlight">unique</span>, specific <span
                        class="highlight">use-cases</span>?
                </p>
                <p class="mb-4 text-blueGray-500">
                    Let us <span class="highlight">collaborate</span> with you to discover a solution and
                    <span class="highlight">implement</span> it for you.
                </p>
            </div>
        </WhatWeDoCard>
        <WhatWeDoCard>
                        <span slot="icon">
                            <i class="fas fa-users"/>
                        </span>
            <span slot="title">Loan Staff</span>
            <div slot="content">
                <p class="mb-4 text-blueGray-500">
                    Not fully clear of the scope / requirements?
                    Don't mind taking up the responsibility of delivering?
                </p>
                <p class="mb-4 text-blueGray-500">
                    Let us provide the <span class="highlight">technical resources</span> to face the
                    unknown together.
                </p>
            </div>
        </WhatWeDoCard>
    </div>
</section>

<style>

    section {
        max-width: var(--container-max-width);
        background-color: #f1f5f9;
        margin: 4rem auto 0 auto;
    }

    .content {
        width: calc(100% - 4rem);
        margin: 0 auto;
        padding: 2rem 0;
        min-width: var(--container-min-width);

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
    }

    .what-we-offer-card {
        max-width: 400px;
        padding: 1rem;
    }

    .what-we-offer-card > blockquote {
        padding: 2rem;
    }

    .offer-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 2rem;
    }

    p, li {
        text-align: justify;
    }

    .highlight {
        color: var(--color-primary);
        font-weight: 600;
    }

    .neu_card {
        border-radius: 24px;
        background: #f1f5f9;
        box-shadow: -9px 9px 15px #c6c9cc, 9px -9px 15px #ffffff;
    }

    polygon {
        color: #f1f5f9;
    }

    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
        cursor: help;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 250px;
        background-color: var(--color-primary);
        color: var(--color-secondary);
        text-align: center;
        border-radius: 6px;
        padding: 1rem;
        text-align: justify;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        margin-left: -60px;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>
