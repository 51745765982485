<div class="neu_card card-root">
    <div class="highlight text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
        <slot name="icon"></slot>
    </div>
    <h6 class="text-xl mb-3 font-semibold highlight">
        <slot name="title"></slot>
    </h6>
    <slot name="content"></slot>
</div>

<style>
    .card-root {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 350px;
        padding: 1.5rem;
    }

    .neu_card {
        border-radius: 24px;
        background: #f1f5f9;
        box-shadow: -9px 9px 15px #c6c9cc, 9px -9px 15px #ffffff;
    }

    .highlight {
        color: var(--color-primary);
        font-weight: 600;
    }
</style>
