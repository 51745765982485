<script>
    import {onDestroy} from "svelte";
    import {contact_mail} from "../../utils/constants";

    const patternVue = "/assets/img/people-and-technology.svg";

    const typewrite_array = [
        'growth.',
        'digital transformation.',
        'reliable delivery.'];

    const longestText = typewrite_array.reduce((a, b)=> a.length > b.length ? a : b);
    let phrase = typewrite_array[0]; // text to be typed
    let statement_index = 0;
    let typedChar = ""; // SECTION displaying typed text
    let char_index = 0;
    let typewriter; // for setInterval/clearInterval
    $: if (!phrase) {
        typedChar = "";
        char_index = typedChar.length;
    }
    let typeMode = 'type'
    const typeChar = () => {
        switch (typeMode) {
            case "type":
                if (char_index < phrase.length) {
                    typedChar += phrase[char_index];
                    char_index += 1;
                } else {
                    typeMode = 'wait';
                }
                break;
            case 'wait':
                // const tempInterval = setInterval(() => {
                //     clearInterval(tempInterval);
                    statement_index++;
                    statement_index %= typewrite_array.length;
                    phrase = typewrite_array[statement_index];
                    typeMode = 'erase';
                // }, 1500)
                break;
            case "erase":
                if (char_index > 0) {
                    typedChar = typedChar.slice(0, -1);
                    char_index -= 1;
                } else {
                    typeMode = 'type'
                }
                break;
        }

    }

    const typing = () => typewriter = setInterval(typeChar, 100);
    onDestroy(() => clearInterval(typewriter));

    typing();
</script>

<section class="hero_root">
    <div class="hero_container">

        <img
                alt="boy and girl near desktop, tablet and mobile"
                class="hero_img neu_popdown"
                style="padding: 1rem"
                src={patternVue}
        />
        <div class="neu_popdown hero_text">
            <div>
                <h1 class="font-bold text-4xl">
                    We are The Cloud Company
                </h1>
                <div style="min-height: 2rem">
                    <h2 class="font-semibold inline-flex flex-wrap text-2xl" style="color: var(--color-secondary1)">
                        Your tech partner for
                        <div class="relative" style="padding-left: .5rem">
                            <span style="color: var(--color-background)">{longestText}</span>
                            <span class="absolute gradient_text" style="left: .5rem; top: 0">
                                {typedChar}
                            </span>
                        </div>
                    </h2>
                </div>
                <p class="mt-4 text-lg leading-relaxed">
                    Software is our craft and passion.
                    We, at The Cloud Company, develop innovative solutions to solve your business
                    problems.
                </p>
                <div class="mt-12">
                    <a
                            class="action_button get-started font-bold px-6 py-4 rounded outline-none
                        focus:outline-none mr-1 mb-1 uppercase text-sm shadow hover:shadow-lg ease-linear
                        transition-all duration-150"
                            href="mailto:{contact_mail}"
                    >
                        Get in Touch
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<style>

    .gradient_text {
        background: var(--color-accent1);
        /*background: -webkit-linear-gradient(to left, #00FF00 0%, #00FFFF 100%);*/
        /*background: -moz-linear-gradient(to left, #00FF00 0%, #00FFFF 100%);*/
        /*background: linear-gradient(to left, #00FF00 0%, #00FFFF 100%);*/
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .neu_popdown {
        border-radius: 1.5rem;
        box-shadow: inset 9px 9px 9px #0b3e6b,
        inset -9px -9px 9px #0f5999;
        padding: var(--card-padding);
    }


    .hero_root {

    }

    .hero_container {
        color: var(--color-white);
        width: calc(100% - 4rem);
        margin: 2rem auto;
        max-width: var(--container-max-width);
        min-width: var(--container-min-width);
        min-height: 60vh;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        gap: 2rem;
    }


    .hero_img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 400px;
        min-width: min(80%, 400px);
    }

    .action_button {
        background-color: var(--color-secondary1);
        color: var(--color-background)
    }

    .hero_text {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: min(100%, 500px);
        max-width: 600px;
    }
</style>
