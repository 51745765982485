<script>
    import {social_media_links} from "../../utils/constants";
    // core components

    let navbarOpen = false;
    let logo = "/assets/logo/logo thick.svg";

    function setNavbarOpen() {
        navbarOpen = !navbarOpen;
    }
</script>


<nav class="navbar-root neu_popup">
    <div class="nav-container">
        <i class="fas {navbarOpen ? 'fa-times' : 'fa-bars'}  hamburger" on:click={setNavbarOpen}/>
        <img alt="The Cloud Company Logo" class="logo" src={logo}/>
        <div class="nav-expansion-panel {navbarOpen ? 'block' : 'hidden'}">
            <div class="nav-links">
                <a target="_blank" href="{social_media_links.twitter}">
                    <button
                            class="text-lightBlue-400 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                            type="button"
                    >
                        <i class="fab fa-twitter"></i>
                    </button>
                </a>
                <a target="_blank" href="{social_media_links.youtube}">
                    <button
                            class="text-red-500 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                            type="button"
                    >
                        <i class="fab fa-youtube"></i>
                    </button>
                </a>
                <a target="_blank" href="{social_media_links.instagram}">
                    <button
                            class=" text-pink-400 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                            type="button"
                    >
                        <i class="fab fa-instagram"
                           style="background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                                  -webkit-background-clip: text;background-clip: text;-webkit-text-fill-color: transparent;"></i>
                    </button>
                </a>
                <a target="_blank" href="{social_media_links.github}">
                    <button
                            class="text-blueGray-800 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                            type="button"
                    >
                        <i class="fab fa-github"></i>
                    </button>
                </a>
            </div>
        </div>
    </div>
</nav>

<style>
    .neu_popup {
        background: var(--color-white);
        box-shadow: inset -5px 5px 5px #aeb0b3,
        inset 5px -5px 5px #ffffff;
    }

    .navbar-root {
        display: flex;
        align-items: center;

        height: var(--navbar-height);
    }

    .nav-container {
        width: 100%;
        min-width: var(--container-min-width);
    }

    .hamburger {
        cursor: pointer;
        font-size: 1.25em;
        position: absolute;
        left: 2rem;
        margin: 1rem 0;
    }

    .logo {
        margin: 0 auto;
    }

    .nav-expansion-panel {
        position: absolute;
        top: var(--navbar-height);
        background: var(--color-white);
        width: 100%;
        border-radius: 0 0 .5em .5em;
        z-index: 9;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 600px) {
        .nav-container{
            width: 80%;
            margin: 0 auto;
            max-width: var(--container-max-width);

            display: flex;
            align-items: center;
        }
        .hamburger {
            display: none;
        }

        .logo {
            margin: 0;
        }

        .nav-expansion-panel {
            display: inline-block;
            position: relative;
            top: 0;
            background: none;
        }

        .nav-links {
            flex-direction: row;
            gap: 1rem;
            justify-content: flex-end;
        }
    }
</style>
