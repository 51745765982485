<!-- App.svelte -->
<script>
  import {Route, Router} from "svelte-routing";

  // Admin Layout
  // Auth Layout
  // No Layout Pages
  import Index from "./views/index/Index.svelte";

  export let url = "";
</script>

<style>
  :global(:root) {
    --color-background: #0D4B82;
    --color-primary: #0D4B82;
    --color-primary1: #3D73A3;
    --color-primary2: #032C50;
    --color-secondary: #F1BF22;
    --color-secondary1: #FCD453;
    --color-secondary2: #7B5D00;
    --color-accent: #F16222;
    --color-accent1: #FC8853;
    --color-accent2: #7B2600;
    --color-white: #efefef;
    --color-black: #0e0e0e;

    --navbar-height: 62px;
    --container-max-width: 1200px;
    --container-min-width: 300px;
    --card-padding: clamp(2rem,5vw,3rem);

  }


  :global(body) {
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    min-width: var(--container-min-width);
  }

  :global(img) {
    max-width: 100%;
  }

</style>

<Router url="{url}">
  <!-- admin layout -->
  <!--  <Route path="admin/*admin" component="{Admin}"/>-->
  <!-- auth layout -->
  <!--  <Route path="auth/*auth" component="{Auth}"/>-->
  <!-- no layout pages -->
  <!--  <Route path="landing" component="{Landing}"/>-->
  <!--  <Route path="profile" component="{Profile}"/>-->
  <Route path="/" component="{Index}"/>
</Router>
