<script>
  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";

  import Hero from "views/index/Hero.svelte";
  import WhatWeDo from "./WhatWeDo.svelte";
  import Footer from "../../components/Footers/Footer.svelte";
</script>

<!--<IndexNavbar/>-->
<!--<Hero/>-->
<!--<Section1/>-->

<!--<PageLinks/>-->

<!--<Section2/>-->

<!--<Section3/>-->



<div class="content">
  <div class="header">
    <IndexNavbar />
  </div>
  <div class="body">
    <div class="content-area">
      <Hero/>
      <WhatWeDo />

<!--      <Section1/>-->
      <Footer/>
    </div>

  </div>
</div>

<style>
  .content {
    /*display: grid;*/
    /*grid-auto-rows: 62px auto;*/
    /*grid-template-areas:*/
    /*  "header"*/
    /*  "body";*/
    height: 100vh;
  }

  .header {
    /*grid-area: header;*/
    width: 100%;
    height: var(--navbar-height);
  }

  .body {
    /*grid-area: body;*/
    height: calc(100% - var(--navbar-height));
    overflow-y: auto;
    /*display: flex;*/
    /*justify-content: center;*/
  }

  .content-area {
    width: 100%;
  }

</style>
